import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "participants":"Teilnehmer",
        "groups":"Gruppen",
        "management": "Einstellungen",
        "scorelists":"Ranglisten",
        "statistics":"Statistiken",
    },
    "fr": {
        "overview" : "Aperçu",
        "participants":"Participant",
        "management": "Paramètres",
        "scorelists":"Classements",
        "statistics":"Statistiques"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;