import {Component} from "react";
import {NavLink} from "react-router-dom";
import localizedTexts from "../texts/EventParticipantsNavigation.texts";

function EventParticipantsNavigationWrapper(){

    return <EventParticipantsNavigation />
}

export class EventParticipantsNavigation extends Component<EventParticipantsNavigationProps, EventParticipantsNavigationState>{

    render(){
        return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
            <NavLink to={"overview"} className="btn btn-outline-dark">
                <span className={"bi-book"}> </span>{localizedTexts.overview}
            </NavLink>
            <NavLink to={"register"} className="btn btn-outline-dark">
                <span className={"bi-pencil-square"}> </span>{localizedTexts.register}
            </NavLink>
            <NavLink to={"result"} className="btn btn-outline-dark">
                <span className={"bi-file-spreadsheet-fill"}> </span>{localizedTexts.result}
            </NavLink>
            <NavLink to={"settlement"} className="btn btn-outline-dark">
                <span className={"bi-clipboard-check-fill"}> </span>{localizedTexts.settlement}
            </NavLink>
            {/*<NavLink to={"gugus"} className="btn btn-outline-dark">
            <span className={"bi-people-fill"}> </span>{localizedTexts.members}
        </NavLink>*/}
        </div>
    }
}

interface EventParticipantsNavigationState {

}

interface EventParticipantsNavigationProps {

}

export default EventParticipantsNavigationWrapper;