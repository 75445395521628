import {Component, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import localizedTexts from "../texts/EventNavigation.texts";
import UserProfileHandler from "../../common/UserProfileHandler";
import {UserData} from "../../core/models/Models";

function EventNavigationWrapper(){
    const [userData, setUserData] = useState<UserData | null | undefined>(null);
    useEffect(() => {
        const loadUserData = async () => {
            try {
                const data = await UserProfileHandler.GetUserData();
                setUserData(data);
            } catch (err) {
                console.error(err);
            }
        };
        loadUserData();
    }, []); // Empty array


    if (!userData) {
        return <div></div>;
    }
    return <EventNavigation userData={userData} />
}

export class EventNavigation extends Component<EventNavigationProps, EventNavigationState>{

    getRoundNavigation(){
        if ((this.props?.userData?.claims?.findIndex((c) => c.key == "roles" && c.value == "WinFireSuperAdmin") ?? -1)  > -1){
            return <NavLink to={"rounds"} className="btn btn-outline-dark">
                <span className={"bi-people-fill"}> </span>{"Runden"}
            </NavLink>
        }
    }

    render() {
        return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
            <NavLink to={"overview"} className="btn btn-outline-dark">
                <span className={"bi-book"}> </span>{localizedTexts.overview}
            </NavLink>
            <NavLink to={"participants"} className="btn btn-outline-dark">
                <span className={"bi-person-lines-fill"}> </span>{localizedTexts.participants}
            </NavLink>
            <NavLink to={"groups"} className="btn btn-outline-dark">
                <span className={"bi-people-fill"}> </span>{localizedTexts.groups}
            </NavLink>
            <NavLink to={"scorelists"} className="btn btn-outline-dark">
                <span className={"bi-list"}> </span>{localizedTexts.scorelists}
            </NavLink>
            <NavLink to={"statistics"} className="btn btn-outline-dark">
                <span className={"bi-clipboard-data-fill"}> </span>{localizedTexts.statistics}
            </NavLink>
            <NavLink to={"management"} className="btn btn-outline-dark">
                <span className={"bi-gear-fill"}> </span>{localizedTexts.management}
            </NavLink>
            {this.getRoundNavigation()}

            {/*<NavLink to={"gugus"} className="btn btn-outline-dark">
            <span className={"bi-people-fill"}> </span>{localizedTexts.members}
        </NavLink>*/}
        </div>
    }
}

interface EventNavigationState {
}

interface EventNavigationProps {
    userData: UserData|undefined;

}

export default EventNavigationWrapper;