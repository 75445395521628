import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Resultaterfassung",
        "labelBarcode":"Schützennummer",
        "labelFirstName":"Vorname",
        "labelLastName":"Nachname",
        "labelCity":"Ort",
        "labelOrganization":"Verein",
        "labelGroup":"Gruppe",
        "labelSelectOrganization":"Verein wählen...",
        "labelShooter":"Schütze",
        "labelPersonNumber":"Personennummer",
        "labelLicenseNumber":"Lizenznummer",
        "labelBirthdate":"Geburtstag",
        "labelStreet":"Strasse",
        "labelZipCode":"PLZ",
        "labelSelectDiscipline":"Disziplin wählen...",
        "labelDiscipline":"Stiche",
        "labelSelectWeapon":"Waffe wählen...",
        "labelTotalAmount":"Totalbetrag:",
        "labelBestShot":"Bester Schuss",
        "labelResult":"Resultat",
        "labelEnterShotsModalTitle":"Resultat / Schüsse eintragen",
        "labelTrainingShots":"Probeschuss",
        "labelSingleShots":"Einzelschuss",
        "labelSeriesShots":"Serie",
        "buttonSave":"Speichern",
        "buttonSaveModal":"Ok",
        "buttonSaveAndPrint":"Speichern & Standblatt Drucken",
        "buttonNextParticipant":"Nächster Teilnehmer",
        "buttonEnterResult":"Resultat Eintragen",
        "buttonExit":"Abbrechen",
        "textPersonNotFound":"Person wurde nicht gefunden in Datenbank",
        "textNotSavedDialog":"Schütze ist noch nicht gespeichert. Zum nächsten Schützen wechseln?",
        "textWeaponNotSelectedDialog":"Waffe wurde noch nicht bei allen Stichen ausgewählt.",
        "textParticipantSaved":"Teilnehmer gespeichert.",
        "textSiteLoading": "Seite lädt. Bitte einen moment Geduld haben."
    },
    "fr": {
        "title" : "Enregistrement des résultats",
        "labelBarcode":"No. du tireur",
        "labelFirstName":"Prénom",
        "labelLastName":"Nom de famille",
        "labelCity":"Lieu",
        "labelOrganization":"Association",
        "labelGroup":"Groupe",
        "labelSelectOrganization":"Choisir l'association...",
        "labelShooter":"Tireur",
        "labelPersonNumber":"No. du personne",
        "labelLicenseNumber":"No. du licence",
        "labelBirthdate":"Date d'anniversaire",
        "labelStreet":"Rue",
        "labelZipCode":"Code postal",
        "labelSelectDiscipline":"Choisir la discipline...",
        "labelDiscipline":"Passes",
        "labelSelectWeapon":"Choisir l'arme...",
        "labelTotalAmount":"Total:",
        "labelBestShot":"Meilleur coup",
        "labelResult":"Résultat",
        "labelEnterShotsModalTitle":"Enregistrer résultat / coups",
        "labelTrainingShots":"Coup d'exercise",
        "labelSingleShots":"Coup par coup",
        "labelSeriesShots":"Série",
        "buttonSave":"Enregistrer",
        "buttonSaveModal":"Ok",
        "buttonSaveAndPrint":"Enregistrer & Imprimer le feuille de stand",
        "buttonNextParticipant":"Prochain participant",
        "buttonEnterResult":"Enregistrer résultat",
        "buttonExit":"Annuler",
        "textPersonNotFound":"Personne non trouvée dans la base de données",
        "textNotSavedDialog":"Le tireur n'est pas encore enregistré. Passer au tireur suivant?",
        "textWeaponNotSelectedDialog":"L'arme n'a pas encore été sélectionnée pour tous les passes",
        "textParticipantSaved":"Participant enregistré.",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant."
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;