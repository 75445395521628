import {useParams} from "react-router";
import React, {ChangeEvent, Component, useRef} from "react";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventGroupsOverview.texts";
import {
    Discipline,
    Group,
    GroupChef,
    GroupParticipant,
    GroupWithParticipants,
    ShootingProgramDefinition
} from "./models/Models";
import WinFireDropDown, {WinFireDropDownElement} from "../common/components/WinFireDropDown";
import * as bootstrap from "bootstrap";
import { Modal } from 'bootstrap';
import PrintDocument from "../common/Helpers/PrintDocument";

function EventGroupsOverviewWrapper(){
    let {eventId} = useParams();
    return <EventGroupsOverview eventId={eventId} />
}

export class EventGroupsOverview extends Component<EventGroupsOverviewProps, EventGroupsOverviewState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventGroupsOverviewProps) {
        super(props);

        this.state = {
            groups: [],
            shootingProgramDefinitions: [],
            selectedShootingProgramDefinition: "",
            selectedShootingProgramDefinitionId: 0,
            organizations: [],
            selectedOrganization: undefined,
            selectedOrganizationNumber: undefined,
            availableParticipants: [],
            noAvailableParticipants: false,
            selectedGroupId: undefined,
            selectedGroupName: "",
            selectedGroupChef: undefined,
            selectedGroupNr: undefined,
            isGroupChefModalOpen: false,
            newGroup: {
                groupNumber: 0,
                groupName: "",
                organizationNumber: "",
                shootingProgramDefinitionId: 0
            },
            resultSheetDefinitions: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try{
            var shootingProgramDefinitions: ShootingProgramDefinition[] = [];
            var selectedShootingProgramDefinition:string|undefined = undefined;
            var selectedShootingProgramDefinitionId:number = 0;
            var resultSheets: {name: string, id: number, isBarcodeSheet: boolean}[] = [];
            var eventDetailsResponse = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            if (eventDetailsResponse.ok) {
                eventDetailsResponse.data.disciplines?.forEach(d => {
                    d.shootingProgramDefinitions?.forEach(spd => {
                        if (spd.isOrderable && spd.isGroupExercise){
                            shootingProgramDefinitions.push(spd);
                            if (!selectedShootingProgramDefinition){
                                selectedShootingProgramDefinition = spd.name!;
                                selectedShootingProgramDefinitionId = spd.id!;
                            }
                        }
                        if (resultSheets.findIndex(rs => rs.id == spd.resultSheetDefinition?.id) == -1){
                            resultSheets.push({name: spd.resultSheetDefinition!.name!, id: spd.resultSheetDefinition!.id!, isBarcodeSheet: spd.resultSheetDefinition?.isBarcodeSheet ?? false});
                        }
                    })
                })

            }

            var result = await this.apiClient.api.eventsGroupsDetail(this.props.eventId ?? "");
            if (result.ok){

                var organizations: {organizationNumber: string, organizationName: string}[] = [];
                organizations.push({organizationName: localizedTexts.textOrganizationSelect??"", organizationNumber: ""});
                var orgs = await this.apiClient.api.eventsOrganizationsDetail(this.props.eventId ?? "");
                if (orgs.ok){
                    orgs.data.forEach(o => {
                        organizations.push({organizationNumber: o.number ?? "", organizationName: o.name?? ""});
                    })
                }

                this.setState({resultSheetDefinitions: resultSheets, groups: result.data, shootingProgramDefinitions: shootingProgramDefinitions, selectedShootingProgramDefinition: selectedShootingProgramDefinition??"", selectedShootingProgramDefinitionId: selectedShootingProgramDefinitionId, organizations: organizations});
            }
        }catch{

        }
    }

    selectShootingProgramDefinition = (shootingProgramDefinition: ShootingProgramDefinition) => {
        this.setState({selectedShootingProgramDefinition: shootingProgramDefinition.name!, selectedShootingProgramDefinitionId: shootingProgramDefinition.id!});
    }

    selectOrganization = (organization: {organizationNumber: string, organizationName: string}) => {
        if (organization.organizationName == localizedTexts.textOrganizationSelect??""){
            this.setState({selectedOrganization: undefined, selectedOrganizationNumber: undefined});
        }else{
            this.setState({selectedOrganization: organization.organizationName, selectedOrganizationNumber: organization.organizationNumber});
        }

    }

    removeFromGroup = async (groupId: number, participantId: number) => {
        if(window.confirm(localizedTexts.textConfirmDelete)){
            var result = await this.apiClient.api.eventsGroupsDelete2(groupId, participantId, this.props.eventId ?? "");
            if (result.ok){
                var selectedProgram = this.state.selectedShootingProgramDefinition;
                await this.loadData();
                this.setState({selectedShootingProgramDefinition: selectedProgram});
            }
        }
    }

    removeGroup = async (groupId: number) => {
        if(window.confirm(localizedTexts.textConfirmDelete)) {
            var result = await this.apiClient.api.eventsGroupsDelete(groupId, this.props.eventId ?? "");
            if (result.ok) {
                var selectedProgram = this.state.selectedShootingProgramDefinition;
                await this.loadData();
                this.setState({selectedShootingProgramDefinition: selectedProgram});
            }
        }
    }

    showEditGroupNrModal = async (groupId: number, groupNr: number) => {
        this.setState({selectedGroupId: groupId, selectedGroupNr: groupNr})
        var personSelector = new bootstrap.Modal(document.getElementById("editGroupNr")!);
        personSelector.toggle();
    }

    loadAvailableParticipants = async (organizationNumber: string, shootingProgramDefinitionId: number, groupId: number) => {
        try {
            var result = await this.apiClient.api.eventsGroupsAvailableParticipantsDetail(shootingProgramDefinitionId, organizationNumber, this.props.eventId ?? "");
            if (result.ok) {
                this.setState({availableParticipants: result.data, noAvailableParticipants: result.data.length == 0, selectedGroupId: groupId});
                var personSelector = new bootstrap.Modal(document.getElementById("personSelector")!);
                personSelector.toggle();
            }
        }catch{

        }
    }
    loadGroupChefInfos = async (groupChefId : number, groupId : number, groupName : string) => {
        this.setState({selectedGroupId : groupId, selectedGroupName: groupName, selectedGroupChef: {id:0}});
        try
        {
            var result = await this.apiClient.api.eventsGroupChefDetail(groupChefId, this.props.eventId ?? "");
            if (result.ok) {
                this.setState({selectedGroupChef: result.data})
            }

        }catch {

        }

        var groupChefInfo = new bootstrap.Modal(document.getElementById("groupChefInfo")!);
        groupChefInfo.toggle();
    }

    updateGroupChef = (evt: React.ChangeEvent<HTMLInputElement>) => {
        var person:any = this.state.selectedGroupChef || {id:0};
        person[evt.currentTarget.id] = evt.currentTarget.value;
        this.setState({selectedGroupChef: person});
    }

    addParticipantToGroup = async (groupId: number, participantId: number) => {
        try{
            var result = await this.apiClient.api.eventsGroupsUpdate2(groupId, participantId, this.props.eventId ?? "");
            if (result.ok){
                var selectedProgram = this.state.selectedShootingProgramDefinition;
                await this.loadData();
                this.setState({selectedShootingProgramDefinition: selectedProgram, availableParticipants: [], noAvailableParticipants: false, selectedGroupId: undefined});
            }
        }catch{

        }
    }

    showNewGroupModal = async () => {
        var personSelector = new bootstrap.Modal(document.getElementById("newGroup")!);
        personSelector.toggle();
    }

    saveGroupChefInfos = async (groupChef: GroupChef) => {
        if (groupChef.id === 0) {
            if (Object.values(groupChef).length < 11)
            {
                alert("Es müssen alle Felder ausgefüllt werden")
                return -1;
            }

            var result = await this.apiClient.api.eventsGroupChefCreate(this.props.eventId ?? "", groupChef)
            if (result.ok)
            {
                var currentGroup = this.state.groups.filter(g => g.id == this.state.selectedGroupId)[0];
                currentGroup.groupChefId = result.data;

                await this.apiClient.api.eventsGroupsUpdate(this.state.selectedGroupId ?? 0, this.props.eventId ?? "", currentGroup)
                alert("Gruppenchef gespeichert");
            }
        }
        else
        {
            if (groupChef.id != undefined) {
                var result2 = await this.apiClient.api.eventsGroupChefUpdate(groupChef.id, this.props.eventId ?? "", groupChef)
                if (result2.ok) {
                    alert("Gruppenchef gespeichert");
                }
            }
        }

        this.setState({selectedGroupId : undefined});
    }

    saveGroupNr = async () => {
        var result = await this.apiClient.api.eventsGroupsGroupNrUpdate(this.state.selectedGroupId??0, this.props.eventId??"", this.state.selectedGroupNr??0);
        if (result.ok) {
            await this.loadData();
            this.setState({selectedGroupNr: undefined, selectedGroupId: undefined});
        }
    }

    addNewGroup = async () => {
        try {
            var group = this.state.newGroup;
            group.organizationNumber = this.state.selectedOrganizationNumber;
            group.shootingProgramDefinitionId = this.state.selectedShootingProgramDefinitionId;
            var result = await this.apiClient.api.eventsGroupsCreate(this.props.eventId??"", group);
            if (result.ok){
                var selectedProgram = this.state.selectedShootingProgramDefinition;
                await this.loadData();
                this.setState({selectedShootingProgramDefinition: selectedProgram, newGroup: {groupNumber: 0, groupName: ""}});
            }
        }catch{

        }
    }

    printGroupLabels = async (groupParticipants: GroupParticipant[], resultsheedId: number, isBarcodeSheet: boolean) => {
        var offset = 0;
        if (isBarcodeSheet){
            var offsetString = prompt(localizedTexts.shooteroffsetmessage);
            offset = parseInt(offsetString??"0");
        }

        var participantNumbers = groupParticipants.map(gp => gp.participantNumber!);
        if (participantNumbers && participantNumbers.length > 0){
            var result = await this.apiClient.api.eventsParticipantsResultsheetCreate(resultsheedId, this.props.eventId ?? "", participantNumbers, {offset: offset});
            if (result.ok){
                await PrintDocument.printPdfDocument(result);
            }
        }
    }

    render() {
        return <div>
            <h1><span className={"bi-people-fill"}> </span>{localizedTexts.title}</h1>
            <div className={"row"}>
            <div className={"col-3 d-flex align-items-center"}>
                    <span className={"h3"}>{localizedTexts.labelProgramDefinition}: </span>&nbsp;&nbsp;
                    <WinFireDropDown
                        buttonText={this.state.selectedShootingProgramDefinition}
                        elements={this.state.shootingProgramDefinitions.map(spd => {
                            return {
                                sourceObject: spd,
                                key: `${spd.id}`,
                                text: spd.name,
                                selectFunction: this.selectShootingProgramDefinition
                            } as WinFireDropDownElement;
                        })}
                        elementFilter={(a: string, b: ShootingProgramDefinition) => {
                            return b.name!.includes(a);
                        }}/>
                </div>
                <div className={"col-4 d-flex align-items-center"}>
                    <span className={"h3"}>{localizedTexts.labelOrganization}: </span>&nbsp;&nbsp;
                    <WinFireDropDown
                        buttonText={this.state.selectedOrganization ?? localizedTexts.textOrganizationSelect ?? ""}
                        elements={this.state.organizations.map(o => {
                            return {
                                sourceObject: o,
                                key: o.organizationNumber,
                                text: o.organizationName,
                                selectFunction: this.selectOrganization
                            } as WinFireDropDownElement;
                        })}
                        elementFilter={(a: string, b: {organizationNumber: string, organizationName: string}) => {
                            return b.organizationName.includes(a) || b.organizationNumber.includes(a);
                        }}/>
                </div>
                <div className={"col-2 d-flex align-items-center"}>
                    <button className={"btn btn-primary"} onClick={() => {this.showNewGroupModal()}} disabled={this.state.selectedOrganizationNumber == undefined }>{localizedTexts.labelNewGroup}</button>
                </div>
            </div>
            <div className={"row"}>
                <table className="table table-striped table-responsive">
                    <thead>
                    <tr>
                        <th>{localizedTexts.headerGroupName}</th>
                        <th>{localizedTexts.headerGroupNumber}</th>
                        <th>{localizedTexts.headerOrganization}</th>
                        <th>{localizedTexts.headerGroupMembers}</th>
                        <th>{localizedTexts.headerActions}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.groups
                            .filter(g => g.shootingProgramDefinitionName == this.state.selectedShootingProgramDefinition)
                            .filter(g => this.state.selectedOrganization ? g.organizationName == this.state.selectedOrganization : true)
                            .map(g => {
                            return <tr>
                                <td>{g.groupName}</td>
                                <td style={{textAlign: 'right', width: '100px'}}>{g.groupNumber}&nbsp;
                                    <button className={"btn btn-light"} style={{marginLeft: '5px'}} onClick={() => this.showEditGroupNrModal(g.id!, g.groupNumber!)}><span className={"bi-pencil-fill"}/></button>
                                </td>
                                <td>{g.organizationName}</td>
                                <td>
                                    <table className={"table"}>
                                <tbody>
                                {g.participants?.map((p) => {
                                    return <tr>
                                        <td>{p.participantNumber}</td>
                                        <td>{p.person?.licenseNumber}</td>
                                        <td>{p.person?.lastName}</td>
                                        <td>{p.person?.firstName}</td>
                                        <td><button className={"btn btn-danger"} onClick={() => this.removeFromGroup(g.id!, p.participantId!)}><span className={"bi-trash3-fill"} /></button></td>
                                        </tr>
                                })}
                                </tbody>
                            </table>
                            </td>
                            <td>
                                <button className={"btn btn-success"} disabled={g.maxGroupSize == g.participantsCount} onClick={() => this.loadAvailableParticipants(g.organizationNumber!, g.shootingProgramDefinitionId!, g.id!)}><span className={"bi-plus-circle-dotted"}/></button>&nbsp;
                                <button className={"btn btn-primary"} onClick={() => {this.loadGroupChefInfos(g.groupChefId ?? 0, g.id!, g.groupName!)}}><span className={"bi-person-workspace"}/> </button>&nbsp;
                                {
                                    this.state.resultSheetDefinitions.map(rs => {
                                        return <div><button className={"btn btn-primary"} style={{marginTop: '4px', marginBottom: '4px'}} onClick={() => {this.printGroupLabels(g.participants ?? [], rs.id, rs.isBarcodeSheet)}}><span className={"bi-printer"}/> {rs.name}</button></div>
                                    })
                                }
                                <button className={"btn btn-danger"} onClick={() => {this.removeGroup(g.id!)}}><span className={"bi-trash3-fill"}/></button>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
            </div>
            {
                this.getAddGroupMemberModal()
            }
            {
                this.getNewGroupModal()
            }
            {
                this.getGroupChefModal()
            }
            {
                this.getEditGroupNrModal()
            }
        </div>
    }

    getNewGroupModal() {
        return <div id={"newGroup"} className="modal fade modal-xl" tabIndex={-1} aria-labelledby="New Group"
                    aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="newEventModalLabel">{localizedTexts.labelNewGroup}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => this.setState({
                                })}></button>
                    </div>
                    <div className="modal-body">
                        <div className={"row"}>
                            <div className={"col-md6"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="name"
                                           placeholder={localizedTexts.labelNewGroupName}
                                           value={this.state.newGroup.groupName!}
                                           onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                               var group = this.state.newGroup;
                                               group.groupName = evt.currentTarget.value;
                                               this.setState({newGroup: group})
                                           }}/>
                                    <label htmlFor="name">{localizedTexts.labelNewGroupName}</label>
                                </div>
                            </div>
                            <div className={"col-md6"}>
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" id="name"
                                           placeholder={localizedTexts.labelNewGroupNumber}
                                           value={this.state.newGroup.groupNumber}
                                           onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                               var group = this.state.newGroup;
                                               group.groupNumber = evt.currentTarget.valueAsNumber;
                                               this.setState({newGroup: group})
                                           }}/>
                                    <label htmlFor="name">{localizedTexts.labelNewGroupNumber}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"modal-footer"}>
                        <button className={"btn btn-primary"} onClick={() => {this.addNewGroup()}}>{localizedTexts.buttonSave}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    getGroupChefModal() {
        return <div id={"groupChefInfo"} className="modal fade modal-xl" tabIndex={-1} aria-labelledby="Group Chef"
                    aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="newEventModalLabel"><span
                            className={"bi-person-workspace"}/>&nbsp;{localizedTexts.labelShowGroupChef} - {this.state.selectedGroupName}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => this.setState({
                                    selectedGroupChef: undefined,
                                    selectedGroupId: undefined,
                                    selectedGroupName: ""
                                })}></button>
                    </div>

                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            <div className={"row"}>
                                <div className={"col-6"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="lastName"
                                               placeholder={"LastName"}
                                               value={this.state.selectedGroupChef?.lastName ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="lastName">{localizedTexts.labelLastName}</label>
                                    </div>
                                </div>
                                <div className={"col-6"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="firstName"
                                               placeholder={"Firstname"}
                                               value={this.state.selectedGroupChef?.firstName ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="firstName">{localizedTexts.labelFirstName}</label>
                                    </div>
                                </div>

                            </div>
                            <div className={"row"}>
                                <div className={"col-6"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="street"
                                               placeholder={"Street"}
                                               value={this.state.selectedGroupChef?.street ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="street">{localizedTexts.labelStreet}</label>
                                    </div>
                                </div>
                                <div className={"col-2"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="streetNumber"
                                               placeholder={"streetNumber"}
                                               value={this.state.selectedGroupChef?.streetNumber ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="streetNumber">{localizedTexts.labelStreetNr}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="zipCode"
                                               placeholder={"Street"}
                                               value={this.state.selectedGroupChef?.zipCode ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="zipCode">{localizedTexts.labelZipCode}</label>
                                    </div>
                                </div>
                                <div className={"col-6"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="city"
                                               placeholder={"StreetNr"}
                                               value={this.state.selectedGroupChef?.city ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="city">{localizedTexts.labelCity}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-6"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="emailAddress"
                                               placeholder={"Street"}
                                               value={this.state.selectedGroupChef?.emailAddress ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="emailAddress">{localizedTexts.labelEMail}</label>
                                    </div>
                                </div>
                                <div className={"col-4"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="phoneNumber"
                                               placeholder={"StreetNr"}
                                               value={this.state.selectedGroupChef?.phoneNumber ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="phoneNumber">{localizedTexts.labelPhoneNr}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-4"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="iban"
                                               placeholder={"Street"}
                                               value={this.state.selectedGroupChef?.iban ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="iban">{localizedTexts.labelIban}</label>
                                    </div>
                                </div>
                                <div className={"col-4"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="bankName"
                                               placeholder={"StreetNr"}
                                               value={this.state.selectedGroupChef?.bankName ?? ""}
                                               onChange={this.updateGroupChef}
                                               readOnly={false}/>
                                        <label
                                            htmlFor="bankName">{localizedTexts.labelBankName}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>

                                <div className={"col-4"}>
                                    <span>
                                        {this.state.selectedGroupChef?.lastName ?? ""} {this.state.selectedGroupChef?.firstName ?? ""}
                                        <br/>
                                        {this.state.selectedGroupChef?.street ?? ""} {this.state.selectedGroupChef?.streetNumber ?? ""}
                                        <br/>
                                        {this.state.selectedGroupChef?.zipCode ?? ""} {this.state.selectedGroupChef?.city ?? ""}
                                        <br/>
                                        {this.state.selectedGroupChef?.phoneNumber ?? ""}
                                        <br/>
                                        {this.state.selectedGroupChef?.emailAddress ?? ""}
                                        <br/>
                                        {this.state.selectedGroupChef?.iban ?? ""}
                                        <br/>
                                        {this.state.selectedGroupChef?.bankName ?? ""}
                                    </span>
                                </div>
                                <div className={"col-8 text-end"}>
                                    <button className={"btn btn-primary"} style={{marginRight: '20px', marginTop: '50px'}}
                                            onClick={() => {
                                               this.saveGroupChefInfos(this.state.selectedGroupChef!)
                                            }}> Speichern
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    getAddGroupMemberModal() {
        return <div id={"personSelector"} className="modal fade modal-xl" tabIndex={-1} aria-labelledby="Select Person"
                    aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="newEventModalLabel">{localizedTexts.labelSelectParticipant}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => this.setState({
                                    availableParticipants: [],
                                    noAvailableParticipants: false,
                                    selectedGroupId: undefined
                                })}></button>
                    </div>
                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            {
                                this.state.availableParticipants.length == 0 && !this.state.noAvailableParticipants
                                    ? <div className={"spinner-border"} role={"status"}/> : ""
                            }
                            <table className={"table table-striped table-responsive"}>
                                <tbody>
                                {
                                    this.state.availableParticipants.map((p) => {
                                        var organization = p.person?.organizations?.find(o => o.isAMember);

                                        return <tr>
                                            <td>{p.person?.lastName}</td>
                                            <td>{p.person?.firstName}</td>
                                            <td>{p.person?.city}</td>
                                            <td>{p.person?.birthday}</td>
                                            <td>{organization?.city} {organization?.name}</td>
                                            <td>
                                                {
                                                    <button className={"btn btn-primary"}
                                                        data-bs-dismiss="modal"
                                                        onClick={() => {
                                                            this.addParticipantToGroup(this.state.selectedGroupId!, p.participantId!)
                                                        }}> +</button>
                                                }
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    this.state.noAvailableParticipants ? <tr>
                                        <td>{localizedTexts.labelNoAvailableParticipants}</td>
                                    </tr> : ""
                                }
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    getEditGroupNrModal() {
        return <div id={"editGroupNr"} className="modal fade modal-sm" tabIndex={-1} aria-labelledby="Edit group Nr" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="newEventModalLabel">{localizedTexts.labelEditGroupNr}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => this.setState({
                                })}></button>
                    </div>
                    <div className="modal-body">
                        <div className={"row"}>
                            <div className={"col-md6"}>
                                <p>{this.state.selectedGroupName!}</p>
                            </div>
                            <div className={"col-md6"}>
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" id="name"
                                           placeholder={localizedTexts.labelNewGroupNumber}
                                           value={this.state.selectedGroupNr}
                                           onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                               var groupNumber = evt.currentTarget.valueAsNumber;
                                               this.setState({selectedGroupNr: groupNumber})
                                           }}/>
                                    <label htmlFor="name">{localizedTexts.labelNewGroupNumber}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"modal-footer"}>
                        <button className={"btn btn-primary"} data-bs-dismiss="modal" onClick={() => {this.saveGroupNr()}}>{localizedTexts.buttonSave}</button>
                    </div>
                </div>
            </div>
        </div>
    }

}

interface EventGroupsOverviewState {
    groups: GroupWithParticipants[];
    shootingProgramDefinitions: ShootingProgramDefinition[];
    selectedShootingProgramDefinition: string;
    selectedShootingProgramDefinitionId: number;
    organizations: { organizationNumber: string, organizationName: string }[];
    selectedOrganization: string | undefined;
    selectedOrganizationNumber: string | undefined;
    availableParticipants: GroupParticipant[];
    noAvailableParticipants: boolean;
    selectedGroupId: number | undefined;
    selectedGroupName : string;
    selectedGroupChef: GroupChef | undefined;
    selectedGroupNr: number | undefined;
    isGroupChefModalOpen: boolean;
    newGroup: Group;
    resultSheetDefinitions: {name: string, id: number, isBarcodeSheet: boolean}[];
}

interface EventGroupsOverviewProps {
    eventId: string | undefined;
}

export default EventGroupsOverviewWrapper;