import React, {Component, Fragment} from "react";
import {Route} from "react-router-dom";
import {Routes} from "react-router";
import EventOverview from "./EventOverview";
import EventDetailViewWrapper from "./EventDetailView";
import EventNavigationWrapper from "./navigations/EventNavigation";
import EventParticipantsOverviewWrapper from "./EventParticipantsOverview";
import EventParticipantsNavigationWrapper from "./navigations/EventParticipantsNavigation";
import EventParticipantsRegistrationWrapper from "./EventParticipantsRegistration";
import EventParticipantsSettlementWrapper from "./EventParticipantsSettlement";
import EventParticipantsResultWrapper from "./EventParticipantsResult";
import EventManagementNavigationWrapper from "./navigations/EventManagementNavigation";
import EventManagementOverviewWrapper from "./EventManagementOverview";
import EventManagementShootingRangesWrapper from "./EventManagementShootingRanges";
import EventScorelistsOverviewWrapper from "./EventScorelistsOverview";
import EventStatisticsWrapper from "./EventStatistics";
import EventGroupsNavigationWrapper from "./navigations/EventGroupsNavigation";
import EventGroupsOverviewWrapper, {EventGroupsOverview} from "./EventGroupsOverview";
import EventRoundsNavigationWrapper from "./navigations/EventRoundsNavigation";
import EventRoundsOverviewWrapper, {EventRoundsOverview} from "./EventRoundsOverview";


export default class EventMainView extends Component<CoreMainViewProps, CoreMainViewState>{

    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div>
                        <Routes>
                            <Route path={"/:eventId/*"} element={<EventNavigationWrapper/>}/>
                        </Routes>
                    </div>
                    <div>
                        <Routes>
                            <Route path={"/:eventId/participants/*"} element={<EventParticipantsNavigationWrapper/>}/>
                            <Route path={"/:eventId/management/*"} element={<EventManagementNavigationWrapper/>}/>
                            <Route path={"/:eventId/groups/*"} element={<EventGroupsNavigationWrapper/>}/>
                            <Route path={"/:eventId/rounds/*"} element={<EventRoundsNavigationWrapper/>}/>
                        </Routes>
                    </div>
                    <br/>
                    <div className={"row"}>
                        <Fragment>
                            <Routes>
                                <Route path={"/"} element={<EventOverview/>}/>
                                <Route path={"/:eventId/overview"} element={<EventDetailViewWrapper/>}/>
                                <Route path={"/:eventId/participants/"} element={<EventParticipantsOverviewWrapper/>}/>
                                <Route path={"/:eventId/participants/overview"} element={<EventParticipantsOverviewWrapper/>}/>

                                <Route path={"/:eventId/participants/register"} element={<EventParticipantsRegistrationWrapper/>}/>
                                <Route path={"/:eventId/participants/register/:participantnumber"} element={<EventParticipantsRegistrationWrapper/>}/>

                                <Route path={"/:eventId/participants/result"} element={<EventParticipantsResultWrapper/>}/>
                                <Route path={"/:eventId/participants/result/:participantnumber"} element={<EventParticipantsResultWrapper/>}/>

                                <Route path={"/:eventId/participants/settlement"} element={<EventParticipantsSettlementWrapper/>}/>
                                <Route path={"/:eventId/participants/settlement/:participantnumber"} element={<EventParticipantsSettlementWrapper/>}/>

                                <Route path={"/:eventId/groups"} element={<EventGroupsOverviewWrapper/>}/>
                                <Route path={"/:eventId/groups/overview"} element={<EventGroupsOverviewWrapper/>}/>

                                <Route path={"/:eventId/statistics"} element={<EventStatisticsWrapper/>}/>

                                <Route path={"/:eventId/management/"} element={<EventManagementOverviewWrapper/>}/>
                                <Route path={"/:eventId/management/overview"} element={<EventManagementOverviewWrapper/>}/>
                                <Route path={"/:eventId/management/shootingranges"} element={<EventManagementShootingRangesWrapper/>}/>

                                <Route path={"/:eventId/scorelists/"} element={<EventScorelistsOverviewWrapper/>}/>
                                <Route path={"/:eventId/scorelists/overview"} element={<EventScorelistsOverviewWrapper/>}/>

                                <Route path={"/:eventId/rounds"} element={<EventRoundsOverviewWrapper/>}/>
                                <Route path={"/:eventId/rounds/overview"} element={<EventRoundsOverviewWrapper/>}/>

                                {/*    <Route path={"/:eventId/resultedit/"} element={<EventResultRegistration />} />
                                    <Route path={"/:eventId/resulteditprogram/"} element={<EventResultRegistrationByProgram />} />
                                    <Route path={"/:eventId/scorelists/"} element={<EventScorelists />} />
                                    <Route path={"/:eventId/groupedit/"} element={<EventDetailView />} />
                                    <Route path={"/:eventId/community/"} element={<EventCommunityOverview />} />
                                    * */
                                }

                            </Routes>
                        </Fragment>

                    </div>
                </div>
            </div>
        )
    }
}

export interface CoreMainViewProps {

}

export interface CoreMainViewState {
}