import {useParams} from "react-router";
import {Component} from "react";
import localizedTexts from "./texts/EventScorelistsOverview.texts";
import {EventRegistratioInformation, Participant, ScorelistDefinition} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";

function EventScorelistsOverviewWrapper(){
    let {eventId} = useParams();
    return <EventScorelistsOverview eventId={eventId} />
}

export class EventScorelistsOverview extends Component<EventScorelistsProps, EventScorelistsState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventScorelistsProps) {
        super(props);

        this.state = {
            scorelistdefinitions: [],
            eventInfos: undefined
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            var infos = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            var result = await this.apiClient.api.eventsScorelistsDetail(this.props.eventId ?? "");
            if (result.ok && infos.ok) {
                this.setState({scorelistdefinitions: result.data, eventInfos: infos.data});
            }
        } catch (e: any) {

        }
    }

    render(){
        return <div>
            <h1> <i className="bi bi-justify"></i>{localizedTexts.title}</h1>

            <table className={"table table-striped table-responsive"}>
                <thead>
                <tr>
                    <th><i className="bi bi-arrow-down-short"></i>{localizedTexts.headerFields} / {localizedTexts.headerPrograms}<i className="bi bi-arrow-right-short"></i></th>
                    {
                        this.state.scorelistdefinitions.map(s => {
                            return <th>{s.name}</th>
                        })
                    }
                    {
                        this.state.scorelistdefinitions.filter(s => s.isSection).map(s => {
                            return <th>{localizedTexts.headerSection} {s.name}</th>
                        })
                    }
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><b>{localizedTexts.labelAll}</b> </td>
                    {
                        this.state.scorelistdefinitions.map(s => {
                            return <td>
                                <div className={"card m-1"}>
                                    <div className={"card-body"}>
                                        <h6 className={"card-title"}>
                                            <i className="bi bi-file-earmark-text"></i>
                                            &nbsp;{localizedTexts.labelScoreList}
                                        </h6>

                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}`}><i className="bi bi-file-earmark-pdf"></i>&nbsp; PDF</a>
                                        <a className={"btn btn-primary m-2"} target={"_blank"}  rel={"noreferrer"}
                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}?fileType=csv`}><i className="bi bi-file-earmark-excel"></i>&nbsp; CSV </a>
                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}?fileType=docx`}><i className="bi bi-file-earmark-word"></i>&nbsp;DOCX</a>
                                    </div>
                                </div>
                                {
                                    s.isGroupExercise ?
                                                <div className={"card m-1"}>
                                                    <div className={"card-body"}>
                                                        <h6 className={"card-title"}>
                                                            <i className="bi bi-file-earmark-ruled"></i>
                                                            &nbsp;{localizedTexts.labelGroupScoreList}
                                                        </h6>
                                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/groups`}><i className="bi bi-file-earmark-pdf"></i>&nbsp; PDF</a>
                                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/groups?fileType=csv`}><i className="bi bi-file-earmark-excel"></i>&nbsp; CSV</a>
                                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/groups?fileType=docx`}><i className="bi bi-file-earmark-word"></i>&nbsp;DOCX</a>
                                                    </div>
                                                </div> : ""
                                }
                            </td>
                        })
                        }
                        {
                            this.state.scorelistdefinitions.filter(s => s.isSection).map(s => {
                            return <td>
                                <div className={"card m-1"}>
                                    <div className={"card-body"}>
                                        <h6 className={"card-title"}>
                                            <i className="bi bi-file-earmark-spreadsheet"></i>
                                            &nbsp;{localizedTexts.labelScoreList}
                                        </h6>
                                        <a className={"btn btn-primary mb-1"} target={"_blank"} rel={"noreferrer"}
                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/section`}><i className="bi bi-file-earmark-pdf"></i>&nbsp; PDF</a>&nbsp;
                                        <a className={"btn btn-primary mb-1"} target={"_blank"} rel={"noreferrer"}
                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/section?fileType=csv`}><i className="bi bi-file-earmark-excel"></i>&nbsp; CSV</a>&nbsp;
                                        <a className={"btn btn-primary mb-1"} target={"_blank"} rel={"noreferrer"}
                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/section?fileType=docx`}><i className="bi bi-file-earmark-word"></i>&nbsp;DOCX</a>
                                    </div>
                                </div>
                            </td>
                            })
                            }
                            </tr>
                            {
                                this.state.eventInfos?.disciplines?.find(d => d.name == "G300")?.fields?.map(f => {
                                    return <tr>
                                        <td>
                                            <b>{f.name}</b>
                                        </td>
                                        {
                                            this.state.scorelistdefinitions.map(s => {
                                                return <td>
                                        <div className={"card m-1"}>
                                            <div className={"card-body"}>
                                                <h6 className={"card-title"}>
                                                    <i className="bi bi-file-earmark-text"></i>
                                                    &nbsp;{localizedTexts.labelScoreList}
                                                </h6>
                                                <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                   href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/${f.id}`}><i className="bi bi-file-earmark-pdf"></i>&nbsp;PDF</a>
                                                <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                   href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/${f.id}?fileType=csv`}><i className="bi bi-file-earmark-excel"></i>&nbsp; CSV</a>
                                                <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                   href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/${f.id}?fileType=docx`}><i className="bi bi-file-earmark-word"></i>&nbsp;DOCX</a>
                                            </div>
                                        </div>
                                        {
                                            s.isGroupExercise ? <div>
                                                <div className={"card m-1"}>
                                                    <div className={"card-body"}>
                                                        <h6 className={"card-title"}>
                                                            <i className="bi bi-file-earmark-ruled"></i>
                                                            &nbsp;{localizedTexts.labelGroupScoreList}
                                                        </h6>
                                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/${f.id}/groups`}><i className="bi bi-file-earmark-pdf"></i>&nbsp;PDF</a>
                                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/${f.id}/groups?fileType=csv`}><i className="bi bi-file-earmark-excel"></i>&nbsp; CSV</a>
                                                        <a className={"btn btn-primary m-2"} target={"_blank"} rel={"noreferrer"}
                                                           href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/${s.shootingProgramDefinitionId}/${f.id}/groups?fileType=docx`}><i className="bi bi-file-earmark-word"></i>&nbsp;DOCX</a>
                                                    </div>
                                                </div>
                                            </div> : ""
                                                }

                                    </td>
                                })
                                }
                                {
                                    this.state.scorelistdefinitions.filter(s => s.isSection).map(s => {
                                    return <td></td>
                                })
                            }
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}

interface EventScorelistsState{
    eventInfos: EventRegistratioInformation | undefined;
    scorelistdefinitions: ScorelistDefinition[];
}

interface EventScorelistsProps{
    eventId: string|undefined;
}

export default EventScorelistsOverviewWrapper;