import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Statistiken",
        "labelExerciseCount":"Gelöste Stiche",
        "labelOrganizationsCount":"Vereine",
        "labelParticipantCount":"Teilnehmerzahl",
        "labelParticipant" : "Teilnehmer",
        "labelName":"Anlass",
        "labelProgramStatistics":"Stichstatistiken",
        "labelDownloadStats":"Statistiken Herunterladen",
        "labelAmmunition" : "Munition",
        "labelAwards" : "Auszeichnungen",
        "labelPayouts" : "Auszahlungen",
        "titleStatistics":"Anlassstatistik",
        "headerAgeCategory":"Alterskategorie",
        "headerOrderCount":"Gelöst",
        "headerIncome":"Einnahmen",
        "headerProgramCharges":"Davon Doppelgeld",
        "headerMunitionType":"Munition",
        "headerMunitionCount":"Anzahl",
        "headerWeapon":"Waffe",
        "headerAwardReceived":"Kranz",
        "headerAwardCardReceived":"Kranzkarte",
        "headerQuota":"Quote",
        "headerPayoutCount":"Anzahl Auszahlungen",
        "headerPayoutAmount":"Auszahlung"
    },
    "fr": {
        "title" : "Statistiques",
        "labelExerciseCount":"Passes vendues",
        "labelOrganizationsCount":"Associations",
        "labelParticipantCount":"Nombre de participants",
        "labelParticipant" : "Participants",
        "labelName":"Manifestation",
        "labelProgramStatistics":"Statistiques de passes",
        "labelDownloadStats":"Télécharger les statistiques",
        "labelAmmunition" : "Munitions",
        "labelAwards" : "Distinctions",
        "labelPayouts" : "Répartitions",
        "titleStatistics":"Statistiques de la fête",
        "headerAgeCategory":"Catégorie d'âge",
        "headerOrderCount":"vendu",
        "headerIncome":"Recettes",
        "headerProgramCharges":"Dont finance de passe",
        "headerMunitionType":"Munition",
        "headerMunitionCount":"Nombre",
        "headerWeapon":"Arme",
        "headerAwardReceived":"Distinction",
        "headerAwardCardReceived":"Carte couronne",
        "headerQuota":"Quota",
        "headerPayoutCount":"Nombre de paiements",
        "headerPayoutAmount":"Répartition"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;