import {useParams} from "react-router";
import React, {Component} from "react";
import localizedTexts from "./texts/EventStatistics.texts";
import ApiClientFactory from "./models/ApiClientFactory";
import {EventStatistics as Statistics} from './models/Models'


function EventStatisticsWrapper() {
    let {eventId} = useParams();
    return <EventStatistics eventId={eventId}/>
}

export class EventStatistics extends Component<EventStatisticsProps, EventStatisticsState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventStatisticsProps) {
        super(props);

        this.state = {
            eventStatistics: undefined
        }
    }

    componentDidMount() {
        this.loadAdvancedStatistics();
    }

    async loadAdvancedStatistics() {
        try {
            var statisticsResult = await this.apiClient.api.eventsEventInformationStatisticsDetail(this.props.eventId ?? "");
            if (statisticsResult.ok) {
                this.setState({eventStatistics: statisticsResult.data});
            }
        } catch {
        }
    }

    render() {
        return <div>
            <h1><span className={"bi-clipboard-data-fill"}></span> {localizedTexts.title}</h1>
            {this.getAdvancedStatistics()}
        </div>
    }

    getAdvancedStatistics() {
        if (!this.state.eventStatistics) {
            return ""
        }
        return <div>
            <div className={"card m-2 mb-5"}>
                <div className={"card-body"}>
                    <h2 className={"card-title"}>
                        {localizedTexts.titleStatistics}
                    </h2>
                    <table className={"table table-striped table-responsive"}>
                        <tbody>
                        <tr>
                            <th>{localizedTexts.labelName}</th>
                            <td>{this.state.eventStatistics.eventName} {this.state.eventStatistics.eventYear}</td>
                        </tr>
                        <tr>
                            <th>{localizedTexts.labelParticipantCount}</th>
                            <td>{this.state.eventStatistics.participantCount}</td>
                        </tr>
                        <tr>
                            <th>{localizedTexts.labelOrganizationsCount}</th>
                            <td>{this.state.eventStatistics.organizationsCount}</td>
                        </tr>
                        <tr>
                            <th>{localizedTexts.labelExerciseCount}</th>
                            <td>{this.state.eventStatistics.exerciseCount}</td>
                        </tr>
                        </tbody>
                    </table>
                    <a className={"btn btn-primary"} target={"_blank"}
                       href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/EventInformation/statisticsdocument?fileType=pdf`}>PDF {localizedTexts.labelDownloadStats}</a>&nbsp;
                    <a className={"btn btn-primary"} target={"_blank"}
                       href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/EventInformation/statisticsdocument?fileType=docx`}>DOCX {localizedTexts.labelDownloadStats}</a>
                </div>
            </div>

            <div className={"card m-2"}>
                <div className={"card-body"}>
                    <h2 className={"card-title"}>
                        {localizedTexts.labelProgramStatistics}
                    </h2>
                    {
                        this.state.eventStatistics.shootingProgramStatistics?.map(sps => {
                            return <div>
                                <div className={"card shadow p-2 mb-2  mt-3 bg-body rounded"}>
                                    <div className={"card-body"}>

                                        <h3 className={"card-title"}>
                                            {sps.shootingProgramName}
                                        </h3>
                                        <div className={"col-2 mt-4"}>
                                            <table
                                                className={"table table-striped table-responsive"}
                                                style={{maxWidth: "200px", minWidth: "200px"}}>
                                                <tbody>
                                                <tr>
                                                    <th>{localizedTexts.labelExerciseCount}</th>
                                                    <td>{sps.exerciseCount}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className={"nav nav-tabs"}>
                                            <li className={"nav-item"}>
                                                <button className="nav-link active"
                                                        id={"participants" + sps.shootingProgramId}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={"#participants_pane" + sps.shootingProgramId}
                                                        type="button" role="tab"
                                                        aria-controls={"#participants_pane" + sps.shootingProgramId}
                                                        aria-selected="true">
                                                    <span className={"bi-person-lines-fill"}></span> {localizedTexts.labelParticipant}

                                                </button>
                                            </li>
                                            <li className={"nav-item"}>
                                                <button className="nav-link "
                                                        id={"ammunition" + sps.shootingProgramId}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={"#ammunition_pane" + sps.shootingProgramId}
                                                        type="button" role="tab"
                                                        aria-controls={"#ammunition_pane" + sps.shootingProgramId}
                                                        aria-selected="false">
                                                    <span className={"bi-vinyl-fill"}></span> {localizedTexts.labelAmmunition}

                                                </button>
                                            </li>
                                            <li className={"nav-item"}
                                                hidden={"0" === sps.awardStatistics?.slice(-1)[0].awardReceivedCount && "0" === sps.awardStatistics?.slice(-1)[0].awardCardReceivedCount}>
                                                <button className="nav-link"
                                                        id={"award" + sps.shootingProgramId}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={"#award_pane" + sps.shootingProgramId}
                                                        type="button" role="tab"
                                                        aria-controls={"#award_pane" + sps.shootingProgramId}
                                                        aria-selected="false">
                                                    <span className={"bi-award-fill"}></span> {localizedTexts.labelAwards}
                                                </button>

                                            </li>
                                            <li className={"nav-item"}
                                                hidden={"0.00" === sps.payoutStatictics?.slice(-1)[0]?.payoutReceivedCount}>
                                                <button className="nav-link"
                                                        id={"payout" + sps.shootingProgramId}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={"#payout_pane" + sps.shootingProgramId}
                                                        type="button" role="tab"
                                                        aria-controls={"#payout_pane" + sps.shootingProgramId}
                                                        aria-selected="false">
                                                    <span className={"bi-cash-coin"}></span> {localizedTexts.labelPayouts}
                                                </button>
                                            </li>
                                        </ul>

                                        <div className={"tab-content"}>
                                            <div id={"participants_pane" + sps.shootingProgramId}
                                                 className={"tab-pane fade show active"}
                                                 aria-labelledby={"participants" + sps.shootingProgramId}>
                                                <div className={"card"}>
                                                    <div className={"card-body"}>
                                                        <h5 className={"card-title"}>
                                                            <span className={"bi-person-lines-fill"}></span> {localizedTexts.labelParticipant}
                                                        </h5>

                                                        <table
                                                            className={"table table-striped table-responsive"}
                                                            style={{maxWidth: "600px"}}>
                                                            <thead>
                                                            <tr>
                                                                <th>{localizedTexts.headerAgeCategory}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerOrderCount}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerIncome}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerProgramCharges}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                sps.orderStatistics?.map(os => {
                                                                    return <tr>
                                                                        <th>{os.ageCategory}</th>
                                                                        <td style={{textAlign: "right"}}>{os.orderCount}</td>
                                                                        <td style={{textAlign: "right"}}>{os.income}</td>
                                                                        <td style={{textAlign: "right"}}>{os.programCharges}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id={"ammunition_pane" + sps.shootingProgramId}
                                                 className={"tab-pane fade"} role="tabpanel"
                                                 aria-labelledby={"ammunition" + sps.shootingProgramId}>
                                                <div className={"card"}>
                                                    <div className={"card-body"}>
                                                        <h5 className={"card-title"}>
                                                            <span className={"bi-vinyl-fill"}></span> {localizedTexts.labelAmmunition}
                                                        </h5>
                                                        <table
                                                            className={"table table-striped table-responsive"}
                                                            style={{maxWidth: "200px"}}>
                                                            <thead>
                                                            <tr>
                                                                <th>{localizedTexts.headerMunitionType}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerMunitionCount}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                sps.munitionStatistics?.map(ms => {
                                                                    return <tr>
                                                                        <th>{ms.name}</th>
                                                                        <td style={{textAlign: "right"}}>{ms.count}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id={"award_pane" + sps.shootingProgramId}
                                                 className={"tab-pane fade"} role="tabpanel"
                                                 aria-labelledby={"award" + sps.shootingProgramId}>
                                                <div className={"card"}>
                                                    <div className={"card-body"}>
                                                        <h5 className={"card-title"}>
                                                            <span className={"bi-award-fill"}></span> {localizedTexts.labelAwards}
                                                        </h5>
                                                        <table
                                                            className={"table table-striped table-responsive"}
                                                            style={{
                                                                maxWidth: "800px",
                                                                wordBreak: "break-word"
                                                            }}>
                                                            <thead>
                                                            <tr>
                                                                <th>{localizedTexts.headerWeapon}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerOrderCount}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerAwardReceived}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerAwardCardReceived}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerQuota}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                sps.awardStatistics?.map(as => {
                                                                    return <tr>
                                                                        <th>{as.weaponName}</th>
                                                                        <td style={{textAlign: "right"}}>{as.orderedCount}</td>
                                                                        <td style={{textAlign: "right"}}>{as.awardReceivedCount}</td>
                                                                        <td style={{textAlign: "right"}}>{as.awardCardReceivedCount}</td>
                                                                        <td style={{textAlign: "right"}}>{as.quota}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id={"payout_pane" + sps.shootingProgramId}
                                                 className={"tab-pane fade"} role="tabpanel"
                                                 aria-labelledby={"payout" + sps.shootingProgramId}>
                                                <div className={"card"}>
                                                    <div className={"card-body"}>
                                                        <h5 className={"card-title"}>
                                                            <span
                                                                className={"bi-cash-coin"}></span> {localizedTexts.labelPayouts}
                                                        </h5>
                                                        <table
                                                            className={"table table-striped table-responsive"}
                                                            style={{maxWidth: "800px"}}>
                                                            <thead>
                                                            <tr>
                                                                <th>{localizedTexts.headerWeapon}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerOrderCount}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerPayoutCount}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerPayoutAmount}</th>
                                                                <th style={{textAlign: "right"}}>{localizedTexts.headerQuota}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                sps.payoutStatictics?.map(ps => {
                                                                    return <tr>
                                                                        <th>{ps.weaponName}</th>
                                                                        <td style={{textAlign: "right"}}>{ps.orderedCount}</td>
                                                                        <td style={{textAlign: "right"}}>{ps.payoutReceivedCount}</td>
                                                                        <td style={{textAlign: "right"}}>{ps.payoutAmount}</td>
                                                                        <td style={{textAlign: "right"}}>{ps.quota}</td>
                                                                    </tr>

                                                                })
                                                            }
                                                            </tbody>
                                                        </table>

                                                        <div className={"col-2 mt-4"}>
                                                        <table
                                                            className={"table table-striped table-responsive col-2"} style={{minWidth : "150px", maxWidth : "150px"}}>
                                                            <thead>
                                                            <tr>
                                                                <th>Differenz zu 60%</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                <tr>
                                                                    <td style={{textAlign: "right"}}>{ (parseFloat(sps.orderStatistics?.slice(-1)[0].programCharges ?? "0.00")  * 0.6 -  parseFloat(sps.payoutStatictics?.slice(-1)[0].payoutAmount ?? "0.00")).toFixed(2) }</td>
                                                                </tr>

                                                            }
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        })
                    }
                </div>
            </div>
        </div>
    }
}

interface EventStatisticsState {
    eventStatistics: Statistics | undefined;
}

interface EventStatisticsProps {
    eventId: string | undefined;
}

export default EventStatisticsWrapper;